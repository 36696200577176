export const Types = {
    GET_OVERVIEW: 'GET_OVERVIEW',
    GET_OVERVIEW_SUCCESS: 'GET_OVERVIEW_SUCCESS',
    GET_OVERVIEW_FAIL: 'GET_OVERVIEW_FAIL',
};

const INITIAL_STATE = {
    data: null,
    loading: false,
    error: null,
};

const data = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.GET_OVERVIEW:
            return { ...state, loading: true };
        case Types.GET_OVERVIEW_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case Types.GET_OVERVIEW_FAIL:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export const Creators = {
    getOverview: () => ({
        type: Types.GET_OVERVIEW,
    }),
    getOverviewSuccess: (data) => ({
        type: Types.GET_OVERVIEW_SUCCESS,
        payload: data,
    }),
    getOverviewFail: (error) => ({
        type: Types.GET_OVERVIEW_FAIL,
        payload: error,
    }),
};

export default data;
