import axios from 'axios';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
export const url = 'https://voz3p605f3.execute-api.us-east-1.amazonaws.com/Production/agent-app-amount-users';

// async function getToken() {
//     const token = await AsyncStorage.getItem('@@DELIVERIE@@:token');
//     return token;
// }

const overviewApi = axios.create({
    baseURL: url,
});

export default overviewApi;
