// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// redux persist
import { persistStore } from 'redux-persist';

import persistReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistReducer, undefined, composeEnhancers(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };
