// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import overviewApi from '../../../services/overviewApi';
import { Types as DataTypes, Creators as DataActions } from '../../ducks/overview';

function* getOverview() {
    try {
        const response = yield call(overviewApi.get);
        yield put(DataActions.getOverviewSuccess(response.data));
    } catch (error) {
        yield put(DataActions.getOverviewFail(error.message));
    }
}

export function* WatchGetOverview() {
    yield takeEvery(DataTypes.GET_OVERVIEW, getOverview);
}

export default function* overviewSagas() {
    yield all([fork(WatchGetOverview)]);
}
