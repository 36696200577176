import axios from 'axios';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
export const devURL = 'http://127.0.0.1:3333';
export const prodURL = 'https://api.seedapp.com.br';

export const baseURL = devURL;

const API_VERSION = 'v1';

// async function getToken() {
//     const token = await AsyncStorage.getItem('@@DELIVERIE@@:token');
//     return token;
// }

const api = axios.create({
    baseURL: `${baseURL}/${API_VERSION}`,
});

api.interceptors.request.use(async (cfg) => {
    cfg.headers['Accept-Language'] = 'pt';
    const authenticated = await isUserAuthenticated();
    if (authenticated) {
        const user = await getLoggedInUser();
        cfg.headers.Authorization = `Bearer ${user.token}`;
    }
    return cfg;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        // parseError(error);
        if (error.config && error.response && error.response.status === 401) {
            /* TODO: REFRESH TOKEN */
        }
        return Promise.reject(error);
    }
);

export default api;
