export const Types = {
    GET_DATA: 'GET_DATA',
    GET_DATA_SUCCESS: 'GET_DATA_SUCCESS',
    GET_DATA_FAIL: 'GET_DATA_FAIL',
    UPDATE_DATE: 'UPDATE_DATE',
};

const formatDate = () => {
    const date = new Date().toLocaleString('en-US');
    return `${date.substring(6, 10)}-${date.substring(0, 2)}-${date.substring(3, 5)}`;
};

formatDate();

const INITIAL_STATE = {
    data: null,
    loading: false,
    error: null,
    date: new Date().toISOString().substring(0, 10),
};

const data = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.GET_DATA:
            return { ...state, loading: true };
        case Types.GET_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case Types.GET_DATA_FAIL:
            return { ...state, error: action.payload, loading: false };
        case Types.UPDATE_DATE:
            return { ...state, date: action.payload }

        default:
            return { ...state };
    }
};

export const Creators = {
    getData: () => ({
        type: Types.GET_DATA,
    }),
    getDataSuccess: (data) => ({
        type: Types.GET_DATA_SUCCESS,
        payload: data,
    }),
    getDataFailed: (error) => ({
        type: Types.GET_DATA_FAIL,
        payload: error,
    }),
    updateDate: (date) => ({
        type: Types.UPDATE_DATE,
        payload: date,
    }),
};

export default data;
