// @flow

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import dashboard from './ducks/dashboard';
import overview from './ducks/overview';

const persistConfig = {
    key: '@USENDDASH@',
    storage: storage,
    whitelist: ['Company'],
};

const rootReducer = combineReducers({
    Auth,
    AppMenu,
    Layout,
    Dashboard: dashboard,
    Overview: overview,
});

export default persistReducer(persistConfig, rootReducer);
