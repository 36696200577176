import axios from 'axios';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
export const url = 'https://voz3p605f3.execute-api.us-east-1.amazonaws.com/Production/agent-app-dashboard';

// async function getToken() {
//     const token = await AsyncStorage.getItem('@@DELIVERIE@@:token');
//     return token;
// }

const api = axios.create({
    baseURL: url,
});

export default api;
