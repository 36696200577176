// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/dashboardApi';
import { Types as DataTypes, Creators as DataActions } from '../../ducks/dashboard';

function* getData() {
    try {
        const response = yield call(api.get);
        yield put(DataActions.getDataSuccess(response.data));
    } catch (error) {
        yield put(DataActions.getDataFailed(error.message));
    }
}

export function* WatchGetData() {
    yield takeEvery(DataTypes.GET_DATA, getData);
}

export default function* dashboardSagas() {
    yield all([fork(WatchGetData)]);
}
