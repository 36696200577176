// @flow
import { all, take } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import dashboardSagas from './sagas/dashboardData';
import overviewSagas from './sagas/overview';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        dashboardSagas(),
        overviewSagas(),
    ]);
}
